<template>
  <v-container class="home">
    <v-row class="justify-center">
      <v-col cols="5">
        <v-card class="pa-6 text-center">
          The purchase page you are trying to access is not available.
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { DateTime } from 'luxon'

export default {
  name: 'HomeView',

  data() {
    return {
      type: null,
      mtek_id: null,
      promo_id: null,
      coupon_id: null,
      can_guest: false,
      prevent_duplicate: false,
      require_signup: 0,
      require_card: 0,
      login_only: 0,
      prompt: 0,
      banner_text: null,
      desktop_image_url: null,
      mobile_image_url: null,
      activation_date: null,
      deactivation_date: null,
    }
  },

  async mounted() {
    //window.localStorage.removeItem('vuex');

    this.$store.state.cart = {}
    this.$store.state.order = {}
    this.$store.state.session = {}
    this.$store.state.purchaseAgreement = null
    this.$store.state.pendingPurchaseAgreement = null
    this.$store.state.signedPurchaseAgreement = null
    this.$store.state.checkoutPending = false
    this.$store.state.cartLoading = false
    this.$store.state.cartLoadingError = null
    this.$store.state.checkoutError = null
    this.$store.state.checkoutErrored = false

    if (this.$store.state.mtek_id !== this.$route.query.mtek_id) {
      this.$store.state.checkoutSuccess = false
    }

    this.promo_id = this.$route.query.promo_id

    if (this.promo_id) {
      await this.$store.dispatch('getConfig', this.promo_id)

      this.type = this.$route.query.type
      this.mtek_id = this.$store.state.config.mtekId
      this.coupon = this.$store.state.config.coupon
      this.can_guest = null
      this.prevent_duplicate = null
      this.require_signup = this.$store.state.config.requireSignup || 0
      this.require_card = this.$store.state.config.saveCard || 0
      this.login_only = this.$store.state.config.loginOnly || 0
      this.prompt = this.$route.query.prompt || 0
      this.banner_text = this.$store.state.config.bannerText
      this.desktop_image_url = this.$store.state.config.desktopImageUrl
      this.mobile_image_url = this.$store.state.config.mobileImageUrl
      this.activation_date = this.$store.state.config.activationDate
      this.deactivation_date = this.$store.state.config.deactivationDate

      this.$store.state.originalLink = window.location.href
      this.$store.state.mtek_id = this.mtek_id
      this.$store.state.coupon = this.coupon
      this.$store.state.require_signup = parseInt(this.require_signup)
      this.$store.state.require_card = parseInt(this.require_card)
      this.$store.state.login_only = parseInt(this.login_only)
      this.$store.state.prompt = parseInt(this.prompt)
      this.$store.state.banner_text = this.banner_text
      this.$store.state.desktop_image_url = this.desktop_image_url
      this.$store.state.mobile_image_url = this.mobile_image_url
    } else {
      this.type = this.$route.query.type
      this.mtek_id = this.$route.query.mtek_id
      this.coupon = this.$route.query.coupon
      this.can_guest = this.$route.query.can_guest
      this.prevent_duplicate = this.$route.query.prevent_duplicate
      this.require_signup = this.$route.query.require_signup || 0
      this.require_card = this.$route.query.require_card || 0
      this.login_only = this.$route.query.login_only || 0
      this.prompt = this.$route.query.prompt || 0
      this.banner_text = this.$route.query.banner_text
      this.desktop_image_url = this.$route.query.desktop_image_url
      this.mobile_image_url = this.$route.query.mobile_image_url

      this.$store.state.originalLink = window.location.href
      this.$store.state.mtek_id = this.mtek_id
      this.$store.state.coupon = this.coupon
      this.$store.state.require_signup = parseInt(this.require_signup)
      this.$store.state.require_card = parseInt(this.require_card)
      this.$store.state.login_only = parseInt(this.login_only)
      this.$store.state.prompt = parseInt(this.prompt)
      this.$store.state.banner_text = this.banner_text
      this.$store.state.desktop_image_url = this.desktop_image_url
      this.$store.state.mobile_image_url = this.mobile_image_url
    }

    const today = DateTime.now().toISO()
    this.activation_date = DateTime.fromISO(this.activation_date).startOf('day').toISO()
    this.deactivation_date = DateTime.fromISO(this.deactivation_date).endOf('day').toISO()
    
    if (this.activation_date && this.deactivation_date) {
      if (today >= this.activation_date && today <= this.deactivation_date) {
        this.canAccess = true
        this.$router.push('/signup')
      } else {
        this.canAccess = false
      }
    } else if (this.activation_date && !this.deactivation_date) {
      if (today >= this.activation_date) {
        this.canAccess = true
        this.$router.push('/signup')
      } else {
        this.canAccess = false
      }
    } else {
      this.canAccess = true
      this.$router.push('/signup')
    }
    

    //http://localhost:8080/?mtek_id=contracts-15261&promo_id=1&prevent_duplicate=true&require_signup=true&coupon=RAMGRCOMP
  }
}
</script>
