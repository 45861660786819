<template>
  <v-container>
    <v-row>
      <v-col sm12>
        <h1>Welcome</h1>
        <pre>
          {{user}}
        </pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'WelcomeView',

  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
</script>
