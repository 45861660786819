<template>
  <v-app>
    <v-main :style="mainStyle">
      <v-container class="py-5">
        <v-row justify="center">
          <v-col xs="8" sm="6" md="4" lg="4" class="mb-3 mx-4">
            <v-responsive>
              <v-img src="https://retail.rayoga.com/uploads/b/33e87161f0316efa05234d8910fa694494e08ebe3d7051d137d126f014ec2893/rayoga_logo_color%20(1)_1586214196.png" />
            </v-responsive>
          </v-col>
        </v-row>
      </v-container>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

  computed: {
    profile() {
      return this.$store.state.session
    },
    mobileImageUrl() {
      return this.$store.state.mobile_image_url
    },
    mainStyle() {
      if (this.mobileImageUrl) {
        return `background-image: url(${this.mobileImageUrl});`
      } else {
        return ''
      }
    },
  },
  methods: {
    logout() {
      window.localStorage.removeItem('vuex')
      window.location.reload()
    },
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },
    generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    },
  },
  mounted() {
    this.challenge = this.generateId(40)
    this.$store.commit('setChallenge', this.challenge)
  },
}
</script>

<style>
.v-sheet {
  border-radius: 15px;
}

.v-main[style] {
  background: #F8F4EF;
  font-family: Gopher;
  background-size: cover;
}

.v-main__wrap {
  background: #F8F4EF;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media(max-width: 1100px) {
  .v-main[style] {
    background-color: #F8F4EF;
  }

  .v-main__wrap {
    background: transparent;
  }
}
</style>
