import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Welcome from '../views/WelcomeView.vue'
import OAuthCallbackView from '../views/OAuthCallbackView.vue'
import SignupView from '../views/SignupView.vue'
import CartView from '../views/CartView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: Welcome,
  },
  {
    path: '/cart',
    name: 'CartView',
    component: CartView,
  },
  {
    path: '/signup',
    name: 'SignupView',
    component: SignupView,
  },
  {
    path: '/oauth/callback',
    name: 'OAuthCallbackView',
    component: OAuthCallbackView,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router