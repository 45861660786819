import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/signature-pad'
//import "./plugins/veevalidate";

import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: '6LfC4yEpAAAAAKQhXlSwVgP0oPm4tfMjwGKX4Xcl',
  loaderOptions: {
    useRecaptchaNet: true
  }
})

router.beforeEach((to, from, next) => {

  if (store.state.session.access_token === null) {
    if (to.path === '/signup' || to.path === '/oauth/callback' || to.path === '/') {
      next()
    } else {
      next('/signup')
    }
  } else {
    next()
  }

  /*const localSession = window.localStorage.getItem('ra-landing-session')

  if (!localSession) {
    if (to.path === '/signup' || to.path === '/oauth/callback' || to.path === '/') {
      next()
    } else {
      next('/signup')
    }
    
    /*store.dispatch('authenticate').then(() => {
      next()
    }).catch(error => {
      if (!error.message.includes('Could not find stored JWT')) {
        console.log('Authentication error', error)
      }
      (to.meta.requiresAuth) ? next('/login') : next()
    })*/
  /*} else {
    store.state.session = JSON.parse(localSession)
    next()
  }*/
})

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
