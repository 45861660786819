var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center justify-center"},[_c('v-col',{attrs:{"sm":"7","lg":"5"}},[_c('v-sheet',{staticClass:"sheet pa-8",attrs:{"elevation":"10"}},[(_vm.bannerText)?_c('div',{staticClass:"banner-text"},[_c('h2',[_vm._v(_vm._s(_vm.bannerText))])]):_vm._e(),(!_vm.showSignup)?_c('div',[_c('h2',{staticClass:"mb-5"},[_vm._v("Login to continue checking out!")]),_c('login-btn',{attrs:{"button-text":"Login"}}),(!_vm.loginOnly)?_c('v-btn',{staticClass:"mt-5",attrs:{"plain":""},on:{"click":function($event){_vm.showSignup = true}}},[_vm._v("Create an account")]):_vm._e()],1):_vm._e(),(_vm.showSignup)?_c('div',[_c('h1',{staticClass:"mb-3"},[_vm._v("Create An Account")]),_c('v-alert',{attrs:{"dismissible":"","outlined":"","type":"error"},model:{value:(_vm.signupError),callback:function ($$v) {_vm.signupError=$$v},expression:"signupError"}},[_vm._v(_vm._s(_vm.signupError))]),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createAccount)}}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"First Name","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Last Name","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Email","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Phone Number","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|confirmed:confirmPassword|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Password","error-messages":errors,"success":valid,"required":"","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Confirm Password","error-messages":errors,"success":valid,"required":"","type":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Preferred Studio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"dense":"","outlined":"","label":"Preferred Studio","hide-details":"","items":_vm.locations,"loading":_vm.locationsLoading,"disabled":_vm.locationsLoading,"item-text":"attributes.name","item-value":"id","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.preferredStudio),callback:function ($$v) {_vm.preferredStudio=$$v},expression:"preferredStudio"}})]}}],null,true)}),_c('v-row',{staticClass:"align-center pt-6"},[_c('v-col',{attrs:{"cols":"1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"dense":"","required":"","error-messages":errors},model:{value:(_vm.marketingAllowed),callback:function ($$v) {_vm.marketingAllowed=$$v},expression:"marketingAllowed"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-left"},[_c('label',{staticClass:"v-label theme--light"},[_vm._v("I would like to receive marketing emails and texts from Ra Yoga")])])],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"dense":"","required":"","error-messages":errors},model:{value:(_vm.signedWaiver),callback:function ($$v) {_vm.signedWaiver=$$v},expression:"signedWaiver"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-left"},[_c('label',{staticClass:"v-label theme--light"},[_vm._v("I have read and agree to the "),_c('a',{attrs:{"target":"_blank","href":"https://rayoga.com/liability-waiver/"}},[_vm._v("Ra Yoga Waiver")])])])],1),_c('v-btn',{staticClass:"mt-5 mb-3",attrs:{"type":"submit","color":"primary","block":"","depressed":"","disabled":_vm.signedWaiver && invalid || !validated}},[_vm._v(" Create Account ")])],1)]}}],null,false,982551752)}),(!_vm.requireSignup)?_c('login-btn',{attrs:{"plain":true,"button-text":"Already have an account?"}}):_vm._e()],1):_vm._e(),(_vm.desktopImageUrl)?_c('div',{staticClass:"desktop-image"},[_c('img',{attrs:{"src":_vm.desktopImageUrl}})]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }