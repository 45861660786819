<template>
  <v-container>
    <v-row class="text-center justify-center">
      <v-col sm="7" lg="5">
        <v-sheet class="sheet pa-8" elevation="10">
          <div v-if="bannerText" class="banner-text">
            <h2>{{bannerText}}</h2>
          </div>
          <div v-if="!showSignup">
            <h2 class="mb-5">Login to continue checking out!</h2>
            <login-btn button-text="Login" />
            <v-btn v-if="!loginOnly" class="mt-5" plain @click="showSignup = true">Create an account</v-btn>
          </div>
          <div v-if="showSignup">
            <h1 class="mb-3">Create An Account</h1>
            <v-alert dismissible outlined type="error" v-model="signupError">{{signupError}}</v-alert>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, handleSubmit }">
              <v-form @submit.prevent="handleSubmit(createAccount)">
                <ValidationProvider name="First Name" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    dense
                    outlined
                    label="First Name"
                    v-model="firstName"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>
                <ValidationProvider name="Last Name" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    dense
                    outlined
                    label="Last Name"
                    v-model="lastName"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid }">
                  <v-text-field
                    dense
                    outlined
                    label="Email"
                    v-model="email"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>
                <ValidationProvider name="Phone Number" rules="required|min:10" v-slot="{ errors, valid }">
                  <v-text-field
                    dense
                    outlined
                    label="Phone Number"
                    v-model="phoneNumber"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>
                <ValidationProvider name="Password" rules="required|confirmed:confirmPassword|min:8" v-slot="{ errors, valid }">
                  <v-text-field
                    dense
                    outlined
                    label="Password"
                    v-model="password"
                    :error-messages="errors"
                    :success="valid"
                    required
                    type="password"
                  />
                </ValidationProvider>
                <ValidationProvider name="Confirm Password" rules="required" vid="confirmPassword" v-slot="{ errors, valid }">
                  <v-text-field
                    dense
                    outlined
                    label="Confirm Password"
                    v-model="confirmPassword"
                    :error-messages="errors"
                    :success="valid"
                    required
                    type="password"
                  />
                </ValidationProvider>
                <ValidationProvider name="Preferred Studio" rules="required" v-slot="{ errors, valid }">
                  <v-select
                    dense
                    outlined
                    label="Preferred Studio"
                    hide-details
                    v-model="preferredStudio"
                    :items="locations"
                    :loading="locationsLoading"
                    :disabled="locationsLoading"
                    item-text="attributes.name"
                    item-value="id"
                    :error-messages="errors"
                    :success="valid"
                    required />
                </ValidationProvider>
                <v-row class="align-center pt-6">
                  <v-col cols="1">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <v-checkbox
                        dense
                        required
                        v-model="marketingAllowed"
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                  <v-col class="text-left">
                    <label class="v-label theme--light">I would like to receive marketing emails and texts from Ra Yoga</label>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="1">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <v-checkbox
                        dense
                        required
                        v-model="signedWaiver"
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                  <v-col class="text-left">
                    <label class="v-label theme--light">I have read and agree to the <a target="_blank" href='https://rayoga.com/liability-waiver/'>Ra Yoga Waiver</a></label>
                  </v-col>
                </v-row>
                <v-btn
                  class="mt-5 mb-3"
                  type="submit"
                  color="primary"
                  block
                  depressed
                  :disabled="signedWaiver && invalid || !validated"
                  >
                  Create Account
                </v-btn>
              </v-form>
            </ValidationObserver>
            <login-btn v-if="!requireSignup" :plain="true" button-text="Already have an account?" />
          </div>

          <div v-if="desktopImageUrl" class="desktop-image">
            <img :src="desktopImageUrl" />
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate/dist/vee-validate.full'

import axios from 'axios'
import { mapGetters } from 'vuex'
import loginBtn from '../components/loginBtn.vue'

export default {
  name: 'SingupView',

  components: {
    loginBtn,
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      password: null,
      confirmPassword: null,
      marketingAllowed: true,
      signedWaiver: false,
      preferredStudio: null,
      showSignup: false,
    }
  },

  computed: {
    ...mapGetters(['locationsLoading']),
    locations() {
      return this.$store.state.locations
    },
    requireSignup() {
      return this.$store.state.require_signup
    },
    loginOnly() {
      return this.$store.state.login_only
    },
    signupError() {
      return this.$store.state.signupError
    },
    bannerText() {
      return this.$store.state.banner_text
    },
    desktopImageUrl() {
      return this.$store.state.desktop_image_url
    },
    mobileImageUrl() {
      return this.$store.state.mobile_image_url
    }
  },

  methods: {
    createAccount() {
      //this.recaptcha();
      this.$store.dispatch('createAccount', {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone_number: this.phoneNumber,
        password: this.password,
        is_marketing_allowed: this.marketingAllowed,
        is_opted_in_to_sms: this.marketingAllowed,
        is_waiver_signed: this.signedWaiver,
        home_location: {
          id: this.preferredStudio,
        },
      })
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('signup')

      // Do stuff with the received token.
      console.log(token);
      const test = await axios.post(`https://ecco-api.rayoga.com/recaptcha`, {
        token,
      })
      console.log(test)
    }
  },
  
  mounted() {
    if (this.requireSignup) {
      this.showSignup = true
    }

    this.$store.dispatch('getLocations')
  }
}
</script>

<style>
.sheet {
  position: relative;
}

.banner-text {
  position: absolute;
  top: -20px;
  width: 85%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #56879a;
  color: #fff;
  padding: 2px 15px;
}

.desktop-image {
  position: absolute;
  top: 15px;
  left: 0;
  transform: translateX(-100%);
  z-index: 0;
}

@media(max-width: 1100px) {
  .desktop-image {
    display: none;
  }
}
</style>